/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useMemo, useState} from "react";
import useLoading from "hooks/useLoading";
import {ActivityDataByUser} from "services/api_v2/User/user.service";
import {ActivityProcessDataType} from "./GenericActivity.types";

import {MRT_ColumnDef, MRT_RowSelectionState} from "material-react-table";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {format} from "date-fns";
import {Box, Icon, Stack, Tooltip, Typography, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {CopyToClipboardIcon} from "components";
import useAlertMessage from "hooks/useAlertMessage";
import {CustomIconButton} from "ui";
import {Types as activitiesTypes} from "store/reducers/activitiesReducer";
import {useBackgroundAndTextColor} from "components/Tables/ActivityTable/utils/useBackgroundAndTextColor";
import {getClientClassification} from "util/getClientClassification";

interface GenericActivityDataProps {
  idActivity?: number;
}

const GenericActivityData = (props: GenericActivityDataProps) => {
  const dispatch = useDispatch();
  const [showAlert] = useAlertMessage();
  const {userInfo} = useSelector((state: any) => state.stateUserInfo);
  const {darkMode} = userInfo.settings;

  const {activityId, activityName} = useParams<{
    activityId: string;
    activityName: string;
  }>();
  const {activitiesPagination} = useSelector(
    (state: any) => state.stateActivitiesReducer,
  );

  const {idActivity = activityId} = props;

  const [tableBody, setTableBody] = useState<ActivityProcessDataType[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [openObservationsModal, setOpenObservationsModal] =
    useState<boolean>(false);
  const [activityDataObservations, setActivityDataObservations] = useState<{
    observations: string;
    processNumber: string;
    id: number;
  }>({observations: "", processNumber: "", id: 0});

  const firstVoyageWithoutUnload = (item: ActivityProcessDataType) => {
    return (
      item?.groupActivity?.processes?.boarding?.voyages?.find(
        (item) => !item?.cargoUnload,
      ) ?? item?.groupActivity?.processes?.boarding?.voyages?.[0]
    );
  };

  const knowledgeByType = (
    item: ActivityProcessDataType,
    type: "House" | "Master",
  ) => {
    return item?.groupActivity?.processes?.knowledges?.find(
      (it) => it?.typeKnowledge === type,
    );
  };

  const openOrCloseObservationsModal = () => {
    setOpenObservationsModal((state) => !state);
  };

  const handleOpenObservationsModal = (data: {
    observations: string;
    processNumber: string;
    id: number;
  }) => {
    setActivityDataObservations(data ?? "");
    openOrCloseObservationsModal();
  };

  const handleChangePaginationTotal = (totalNumberOfPages: number) => {
    dispatch({
      type: activitiesTypes.SET_ACTIVITIES_PAGINATION,
      activitiesPagination: {
        ...activitiesPagination,
        totalNumberOfPages: totalNumberOfPages,
      },
    });
  };

  const activitiesToCompleteInBatch = Object.keys(rowSelection).map((item) => ({
    id: +item,
    processNumber:
      tableBody?.find((a) => a.id === +item)?.groupActivity?.processes
        ?.processNumber ?? "",
  }));

  const {definedRanges, companyName, refNext} = useSelector(
    (state: any) => state.stateDashboardFilter,
  );
  const {checkBackgroundAndTextColor} = useBackgroundAndTextColor();
  const theme = useTheme();
  const tableColumns = useMemo<MRT_ColumnDef<ActivityProcessDataType>[]>(
    () => [
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.processNumber,
        id: "processNumber",
        header: "Ref Next",
        size: 160,
        Cell: ({row}) => (
          <Stack direction="row" justifyContent={"space-between"}>
            {row?.original?.groupActivity?.processes?.isPriority && (
              <Icon color="error">priority_hight</Icon>
            )}
            <Typography
              component="span"
              sx={{
                fontSize: pxToRem(12),
                verticalAlign: "middle",
                m: `auto ${pxToRem(5)}`,
                color: row?.original?.groupActivity?.processes?.isPriority
                  ? theme.palette.error[600]
                  : undefined,
              }}
            >
              {row?.original?.groupActivity?.processes?.processNumber}
            </Typography>
            <CopyToClipboardIcon
              textToBeCopied={
                row?.original?.groupActivity?.processes?.processNumber
              }
              color={checkBackgroundAndTextColor(row).text}
            />
          </Stack>
        ),
      },
      {
        id: "task",
        header: "Tarefa",
        size: 160,
        Cell: () => (
          <Typography
            component="span"
            sx={{
              fontSize: pxToRem(12),
              verticalAlign: "middle",
              m: `auto ${pxToRem(5)}`,
            }}
          >
            {decodeURIComponent(activityName)}
          </Typography>
        ),
      },
      {
        accessorFn: (process) => process?.estimatedStart,
        id: "estimatedStart",
        header: "Previsão início",
        size: 180,
        Cell: ({row}) =>
          (row?.original?.estimatedStart &&
            format(
              new Date(row?.original?.estimatedStart ?? ""),
              "dd/MM/yyyy",
            )) ??
          "-",
      },
      {
        accessorFn: (process) => process?.estimatedFinish,
        id: "estimatedFinish",
        header: "Previsão término",
        size: 180,
        Cell: ({row}) =>
          (row?.original?.estimatedFinish &&
            format(
              new Date(row?.original?.estimatedFinish ?? ""),
              "dd/MM/yyyy",
            )) ??
          "-",
      },
      {
        accessorFn: (process) => process?.observation ?? "-",
        id: "observation",
        header: "Observações",
        size: 170,
        Cell: ({row}) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{width: "100%"}}
          >
            <Typography
              component="span"
              sx={{
                fontSize: pxToRem(12),
                color: "text.primary",
                verticalAlign: "middle",
                m: `auto ${pxToRem(5)}`,
                whiteSpace: "nowrap",
                width: "80%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row.original?.observation ?? "-"}
            </Typography>
            <Tooltip title={"Editar observações"} placement="right" arrow>
              <Box>
                <CustomIconButton
                  iconName="edit"
                  iconSize={16}
                  onClick={() =>
                    handleOpenObservationsModal({
                      observations: row.original?.observation ?? "",
                      processNumber:
                        row?.original?.groupActivity?.processes?.processNumber,
                      id: row?.original?.id,
                    })
                  }
                  sx={{
                    color: checkBackgroundAndTextColor(row).text,
                  }}
                />
              </Box>
            </Tooltip>
          </Stack>
        ),
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.client?.name,
        id: "client",
        header: "Cliente",
        size: 270,
      },

      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.originAgent?.name,
        id: "originAgent",
        header: "Agente de origem",
        size: 190,
        Cell: ({row}) =>
          row?.original?.groupActivity?.processes?.originAgent?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.incoterm?.name,
        id: "incoterm",
        header: "Incoterm",
        size: 190,
        Cell: ({row}) =>
          row?.original?.groupActivity?.processes?.boarding?.incoterm?.name ??
          "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.origin?.name,
        id: "origin",
        header: "Origem",
        size: 180,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.boarding?.origin?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.destination?.name,
        id: "destination",
        header: "Destino",
        size: 180,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.boarding?.destination?.name ??
          "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.transportCompany?.name,
        id: "transportCompany",
        header: "Cia. transporte",
        size: 180,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.boarding?.transportCompany
            ?.name ?? "-",
      },
      //fazer condicional do navio de acordo com o tipo de viagem para pegar o navio da viagem atual
      {
        accessorFn: (process) =>
          firstVoyageWithoutUnload(process)?.vessel?.name,
        id: "vessel",
        header: "Navio",
        size: 180,
        Cell: ({row}) =>
          firstVoyageWithoutUnload(row?.original)?.vessel?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process.groupActivity?.processes?.boarding?.statusBoarding?.name,
        id: "statusBoarding",
        header: "Situação embarque",
        size: 200,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.boarding?.statusBoarding
            ?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.voyages?.at(0)
            ?.estimatedCargoLoad,
        id: "estimatedCargoLoad",
        header: "ETD",
        size: 120,
        Cell: ({cell, renderedCellValue}) =>
          renderedCellValue
            ? format(new Date(cell.getValue<string>()), "dd/MM/yyyy")
            : "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.voyages?.at(0)
            ?.cargoLoad,
        id: "cargoLoad",
        header: "Data embarque",
        size: 180,
        Cell: ({cell, renderedCellValue}) =>
          renderedCellValue
            ? format(new Date(cell.getValue<string>()), "dd/MM/yyyy")
            : "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.voyages?.at(-1)
            ?.estimatedCargoUnload,
        id: "estimatedCargoUnload",
        header: "ETA",
        size: 120,
        Cell: ({cell, renderedCellValue}) =>
          renderedCellValue
            ? format(new Date(cell.getValue<string>()), "dd/MM/yyyy")
            : "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.voyages?.at(-1)
            ?.cargoUnload,
        id: "cargoUnload",
        header: "Data desembarque",
        size: 200,
        Cell: ({cell, renderedCellValue}) =>
          renderedCellValue
            ? format(new Date(cell.getValue<string>()), "dd/MM/yyyy")
            : "-",
      },
      {
        accessorFn: (process) => knowledgeByType(process, "House")?.number,
        id: "hbl",
        header: "HBL",
        size: 120,
        Cell: ({row}) => knowledgeByType(row.original, "House")?.number ?? "-",
      },
      {
        accessorFn: (process) => knowledgeByType(process, "Master")?.number,
        id: "mbl",
        header: "MBL",
        size: 120,
        Cell: ({row}) => knowledgeByType(row.original, "Master")?.number ?? "-",
      },
      {
        accessorFn: (process) =>
          knowledgeByType(process, "Master")?.directMaster,
        id: "directMaster",
        header: "Master direto",
        size: 160,
        Cell: ({row}) =>
          knowledgeByType(row.original, "Master")?.directMaster ?? "-",
      },
      {
        accessorFn: (process) =>
          process.groupActivity?.processes?.clientReference,
        id: "clientReference",
        header: "Ref. cliente",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.clientReference ?? "-",
      },
      {
        accessorFn: (process) => process?.groupActivity?.processes?.sale?.name,
        id: "sale",
        header: "Vendedor",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.sale?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.shipper?.name,
        id: "shipper",
        header: "Exportador",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.shipper?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.consignee?.name,
        id: "consignee",
        header: "Importador",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.consignee?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.externalReference,
        id: "externalReference",
        header: "Ref. externa",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.externalReference ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.boarding?.bookingNumber,
        id: "bookingNumber",
        header: "Nº booking",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.boarding?.bookingNumber ??
          "-",
      },
      {
        accessorFn: (process) =>
          firstVoyageWithoutUnload(process)?.voyageFlight,
        id: "voyageFlight",
        header: "Viagem navio",
        size: 170,
        Cell: ({row}) =>
          firstVoyageWithoutUnload(row.original)?.voyageFlight ?? "-",
      },
      {
        accessorFn: (process) =>
          process?.groupActivity?.processes?.legalPerson?.name,
        id: "legalPerson",
        header: "Empresa",
        size: 170,
        Cell: ({row}) =>
          row.original?.groupActivity?.processes?.legalPerson?.name ?? "-",
      },
      {
        accessorFn: (process) =>
          knowledgeByType(process, "Master")?.deconsolidationNumber,
        id: "deconsolidationNumber",
        header: "Nº CE master",
        size: 160,
        Cell: ({row}) =>
          knowledgeByType(row.original, "Master")?.deconsolidationNumber ?? "-",
      },
      {
        accessorFn: (process) => {
          return getClientClassification(
            process?.groupActivity?.processes?.client?.client
              ?.clientClassifications,
            process?.groupActivity?.processes?.operation,
            process?.groupActivity?.processes?.modality,
          )?.classification?.name;
        },
        id: "clientClassification",
        header: "Classificação do cliente",
        size: 160,
      },
    ],
    [darkMode],
  );

  const {
    userInfo: {
      user: {id: userId},
    },
  } = useSelector((state: any) => state.stateUserInfo);

  const [tableLoading, setTableLoading] = useState(true);

  const [setLoading] = useLoading();

  const getActivityData = async (
    id = idActivity,
    startDate?: string,
    endDate?: string,
    idClient?: number,
  ) => {
    try {
      const {
        data: {data, meta},
      } = await ActivityDataByUser(
        id,
        userId,
        `Em andamento,Não iniciada,Paralisada`,
        activitiesPagination.pageIndex + 1,
        activitiesPagination.pageSize,
        startDate,
        endDate,
        idClient,
        refNext,
      );

      setTableBody(data);
      handleChangePaginationTotal(meta?.total);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setLoading(false);
      setTableLoading(false);
    }
  };

  useEffect(() => {
    const [startDate, endDate] = definedRanges;
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate =
      endDate === null ? undefined : format(endDate, "yyyy-MM-dd");

    getActivityData(formattedStartDate, formattedEndDate, companyName?.id);
  }, [activitiesPagination.pageIndex, activitiesPagination.pageSize]);

  return {
    tableColumns,
    tableLoading,
    tableBody,
    getActivityData,
    rowSelection,
    setRowSelection,
    activitiesToCompleteInBatch,
    setTableBody,
    openObservationsModal,
    openOrCloseObservationsModal,
    activityDataObservations,
  };
};

export default GenericActivityData;
